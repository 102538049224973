/** @format */

import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import Footer from "src/components/Footer";
import UserData from "./UserDetails";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import DownloadIcon from "@mui/icons-material/Download";
import { useUserDataContext } from "src/hooks/userDataContext";
import handleDownload from "../components/GenratePdf";
import PageTitle from "src/components/PageTitle";

export default function ViewUserDetails() {
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  const { appDataList, cmsPages } = useUserDataContext();
  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false);
  };
  const handleChangePassword = () => {
    setOpenPasswordModal(false);
  };
  const handleClick = () => {
    handleDownload([appDataList], cmsPages);
  };
  const PageTitle = styled(Box)(
    ({ theme }) => `
        padding: ${theme.spacing(4)};
        background:white !important;
`
  );
  return (
    <>
      <PageTitle className='MuiPageTitle-wrapper' sx={{ width: "100%" }}>
        <Container maxWidth='lg'>
          <Grid
            container
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            ml={-2}
          >
            <Grid>
              <Typography variant='h3' component='h3' gutterBottom>
                View User Details
              </Typography>
            </Grid>
            <Grid>
              <Button variant='outlined' onClick={handleOpenPasswordModal}>
                Change Password
              </Button>

              <IconButton
                color='primary'
                sx={{ marginLeft: "25px", border: "1px solid" }}
                onClick={handleClick}
              >
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </PageTitle>
      <Container>
        <Grid container justifyContent='center' spacing={2} mb={10}>
          <UserData
            handleChangePassword={handleChangePassword}
            openPasswordModal={openPasswordModal}
            handleClosePasswordModal={handleClosePasswordModal}
          />
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
