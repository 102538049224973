/** @format */

import React from "react";
import { URL_CONFIG } from "src/config/urlConfig";
import queryString from "query-string";


//DashBoard Api


export const fetchDashboardDataAPI = async (token: any) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/accounts/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch dashboard data");
    }
  } catch (error) {
    throw new Error("Error fetching dashboard data:", error.message);
  }
};

//UserDataTable Api

export const fetchUserDataList = async (searchQuery: any, pageNo: number) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: 10,
    pageNo: pageNo,
    status: "ACTIVE",
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//updateUserRegularNotation Api

export const updateUserRegularNotation = async (
  userId: any,
  regularNotation: any
) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/users/regular-notation`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: userId,
          regularNotation: regularNotation ? "regular" : "irregular",
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(
      "There was a problem with the fetch operation:",
      error.message
    );
  }
};

//AppDataTable Api

export const fetchApplicantsDataList = async (
  searchQuery: any,
  pageNo: any
) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: 10,
    pageNo: pageNo,
    status: "active",
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/applicants/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//MutualFunDataTable Api

export const fetchMutualFundsDataList = async (pageNo: any) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: 10,
    pageNo: pageNo,
    status: "active",
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/mutual-funds/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    console.log(newDataList);
    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//CmsPages Api

export const fetchCmsPagesData = async () => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/cms-pages/list`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//InquiryPage Details Api

export const fetchInquiryDataList = async (
  searchQuery: any,
  pageNo: number
) => {
  const myObject = {
    sortBy: "insertAt",
    orderBy: "DESC",
    limit: 10,
    pageNo: pageNo,
    status: "active",
    ...(searchQuery ? { search: searchQuery } : {}),
  };
  const queryStringResult = queryString.stringify(myObject);

  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/inquiries/list?${queryStringResult}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const newDataList = data?.data || [];

    return newDataList;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};

//AccountDetails Api

export const fetchAccountDetails = async (adminId: any) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/accounts/details?adminUserId=${adminId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};
 
//ApplicantsDetails Api

export const fetchApplicantDetails = async (applicantId: any) => {
  try {
    const response = await fetch(
      `${URL_CONFIG.BASE_URL}/admin/api/v1/applicants/details?applicantId=${applicantId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw new Error("Error fetching data:", error.message);
  }
};
